import React from 'react';
import styled from 'styled-components';
import { ShoppingCartOutlined,SearchOutlined,FavoriteBorderOutlined} from '@material-ui/icons';

const Info= styled.div`
    opacity: 0;
    width:100%;
    height:100% ;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;

    `;

const Container = styled.div`
flex:2;
margin: 5px;
min-width: 280px;
height: 350px;
align-items: center;
justify-content: center;
display:flex;
background-color: #f5fbfd;
position: relative;

&:hover ${Info}{  //icon are viewed only the cursor placed there
    opacity: 1;

}
`;

const Circle = styled.div`
    width: 200px;
    height:200px;
    border-radius: 50%;
    background-color: white;
    position: absolute;

`;

const Image= styled.img`
height: 75%;
z-index: 2;
`;

const Icon = styled.div`
width: 40px;
height: 40px;
border-radius:50%;
background-color:white;
display: flex;
align-items: center;
justify-content: center;
margin:10px;
transition: all 0.5s ease;  //animation


&:hover{
    background-color: #e9f5f5;// to make the icon little big when touched
    transform: scale(1.1);
}
`;

const Product = ({item}) => {
  return (
    <Container>
        <Circle/>
        <Image src={item.img}/>
        <Info>
            <Icon>
                <ShoppingCartOutlined/>

            </Icon>
            <Icon>
                <SearchOutlined/>

            </Icon>
            <Icon>
                <FavoriteBorderOutlined/>

            </Icon>
        </Info>

    </Container>
  )
}

export default Product