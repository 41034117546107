import React, { useState } from 'react'
import {ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons"
import styled from 'styled-components'

import {sliderItems} from "../data";




const Container=styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position:relative;
    overflow: hidden; //to view in horizontal mode
`
const Arrow =styled.div`
    width: 50px;
    height: 50px;
    background-color:white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;//to split the arrows to both sides
    top: 0;
    bottom: 0;
    left: ${props=> props.direction === "left" && "10px"};
    right: ${props=> props.direction === "right" && "10px"};
    margin: auto;
    cursor: pointer;
    opacity: 0.5;
    z-index: 2;
    
`
const Wrapper=styled.div`
height: 100px;
display: flex;
transition: all 1.5s ease;
transform: translateX(${props=>props.slideIndex *-100}vw);//to move the images to the x axis

`
const Slide = styled.div`
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
background-color: #${props=>props.bg}; //accept the properties from slide tag
`
const ImgContainer = styled.div`
height: 80%;
flex:1;
margin-left:80px;
margin-top: 50px;

`
const Image=styled.div`
   height: 70%;
   
`
const InfoContainer = styled.div`
flex:1;
padding:50px;
`
const Title = styled.h1`
font-size: 70px;
`
const Desc = styled.p`
margin:50px 0px;
font-size: 20px;
font-weight: 500;
letter-spacing:3px;
`
const Button  = styled.button`
padding:10px;
font-size: 20px;
background-color:transparent;
cursor: pointer;
`
const Slider = () => {
    const [slideIndex,setSlideIndex]=useState(0);
    const handleClick =(direction)=>{
        if(direction==="left"){
            setSlideIndex(slideIndex> 0 ? slideIndex -1 : 2)
        }
        else{
            setSlideIndex(slideIndex < 2 ? slideIndex +1 : 0)
        }
    };
  return (

    <Container>
        <Arrow direction="left" onClick={()=>handleClick("left")}>
            <ArrowLeftOutlined/>
        </Arrow>
        <Wrapper slideIndex={slideIndex}>
            {sliderItems.map(item=>(
            <Slide bg={item.bg} key={item.id}>
            <ImgContainer>
            <Image>
            <img  src = {item.src} alt=""/> 
            </Image>
            </ImgContainer>
            
            <InfoContainer>
                <Title>{item.title}</Title>
                <Desc>{item.desc} </Desc>
                <Button>SHOW NOW</Button>
            </InfoContainer>
            </Slide>
            ))}

            
        


        </Wrapper>
        <Arrow direction="right" onClick={()=>handleClick("right")}>
            <ArrowRightOutlined/>
        </Arrow>

    </Container>
  )
}

export default Slider